import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<WeekStatisticsView>({})
export default class WeekStatisticsView extends Vue {
  @Prop()
  protected items!: any;

  @Prop()
  protected columns!: StatisticsColumn[];

  @Prop()
  protected options!: StatisticsOptions;

  protected defaultOptions: StatisticsOptions = {

  }

  protected mounted() {
    this.defaultOptions = { ...this.defaultOptions, ...this.options };
  }

  protected tableTotal() {
    let total = 0;

    this.items.forEach((item: any) => {
      this.columns.forEach((column: any, index: number) => {
        if (column.key === 'week') {
          total += item.week[index - 1].total;
        }
      });
    });

    return total;
  }

  protected columnTotal(columnIndex : number) {
    let total = 0;

    this.items.forEach((item: any) => {
      this.columns.forEach((column: any, index: number) => {
        if (column.key === 'week' && columnIndex === index) {
          total += item.week[index - 1].total;
        } else if (item.data) {
          if (column.key !== 'name' && columnIndex === index) {
            total += item.data[column.key];
          }
        } else if (column.key !== 'name' && columnIndex === index) {
          total += item[column.key];
        }
      });
    });

    return total;
  }

  protected rowTotal(item: any, column?: any) {
    let total = 0;

    this.columns.forEach((column: any, index: number) => {
      if (column.key === 'week') {
        total += item.week[index - 1].total;
      }
    });

    return total;
  }

  protected goToLink(link: string) {
    this.$router.push(link);
  }

  public isFunction(object: any) {
    return object && {}.toString.call(object) == '[object Function]';
  }
}

export interface StatisticsOptions {
  showStatusLabels?: boolean;
  totalColumnName?: string;
}

export interface StatisticsColumn {
  key: string;
  name: string;
  class: string;
  format?: Function;
  transform?: Function;
}
