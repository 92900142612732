var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _vm.$store.state.Auth.has_management_info || _vm.isSupervisorView
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "info-icon",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openReadMoreDialog(
                                                            "contract_kpis"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("info_outline")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2265109191
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("klik voor meer info")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-flex", [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v(
                                    "Contract KPI's " +
                                      _vm._s(_vm.progressLabels.month)
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "elementPanel elementPanel--spaced"
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "text-center": "" } },
                                [
                                  _vm.isLoading
                                    ? _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center"
                                        }
                                      })
                                    : _vm._e(),
                                  !_vm.isLoading
                                    ? [
                                        _c(
                                          "v-layout",
                                          {
                                            staticClass: "tw-justify-between",
                                            attrs: { wrap: "" }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm2: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: _vm.$store.state.Auth
                                                        .has_management_info
                                                        ? "/rapportage/opgeleverd-matrix"
                                                        : "/bureau"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .contract_score_this_month
                                                                  .doorlooptijd_small,
                                                                93,
                                                                90,
                                                                true
                                                              )
                                                            ],
                                                            icon: "replay_30",
                                                            amount:
                                                              _vm.statistics
                                                                .contract_score_this_month
                                                                .doorlooptijd_small +
                                                              "%",
                                                            description:
                                                              "Standaard doorlooptijd"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm2: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: _vm.$store.state.Auth
                                                        .has_management_info
                                                        ? "/rapportage/opgeleverd-matrix"
                                                        : "/bureau"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .contract_score_this_month
                                                                  .doorlooptijd_large,
                                                                80,
                                                                75,
                                                                true
                                                              )
                                                            ],
                                                            icon: "forward_30",
                                                            amount:
                                                              _vm.statistics
                                                                .contract_score_this_month
                                                                .doorlooptijd_large +
                                                              "%",
                                                            description:
                                                              "Standaard 30+ doorlooptijd"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm2: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: _vm.$store.state.Auth
                                                        .has_management_info
                                                        ? "/rapportage/opgeleverd-matrix"
                                                        : "/bureau"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .contract_score_this_month
                                                                  .doorlooptijd_ves,
                                                                80,
                                                                75,
                                                                true
                                                              )
                                                            ],
                                                            icon: "forward_30",
                                                            amount:
                                                              _vm.statistics
                                                                .contract_score_this_month
                                                                .doorlooptijd_ves +
                                                              "%",
                                                            description:
                                                              "VES nulmeting doorlooptijd"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm2: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: _vm.$store.state.Auth
                                                        .has_management_info
                                                        ? "/rapportage/opgeleverd-matrix"
                                                        : "/bureau"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .contract_score_this_month
                                                                  .ftr_small,
                                                                95,
                                                                90,
                                                                true
                                                              )
                                                            ],
                                                            icon: "done_all",
                                                            amount:
                                                              _vm.statistics
                                                                .contract_score_this_month
                                                                .ftr_small +
                                                              "%",
                                                            description:
                                                              "Standaard FTR"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "", sm2: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: _vm.$store.state.Auth
                                                        .has_management_info
                                                        ? "/rapportage/dossiers-uit-termijn?almost_kpi=twenty_six_weeks_overdue&sort=planned_at&sort_order=DESC"
                                                        : "/bureau"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .contract_score_this_month
                                                                  .twenty_six_weeks_overdue,
                                                                0,
                                                                1,
                                                                false
                                                              )
                                                            ],
                                                            icon: "watch_later",
                                                            amount:
                                                              "" +
                                                              _vm.statistics
                                                                .contract_score_this_month
                                                                .twenty_six_weeks_overdue,
                                                            description:
                                                              "# dossiers langer dan 26 weken"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showOperationalKpis
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "info-icon",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openReadMoreDialog(
                                                            "status"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("info_outline")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2775563743
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("klik voor meer info")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-flex", [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v("Operationele KPI's")
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "elementPanel elementPanel--spaced"
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "text-center": "" } },
                                [
                                  _vm.isLoading
                                    ? _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center"
                                        }
                                      })
                                    : _vm._e(),
                                  !_vm.isLoading
                                    ? [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs6: "", md4: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "/reports?status=opname_done"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .status_totals[
                                                                  "opname_done"
                                                                ]
                                                              )
                                                            ],
                                                            icon: "thumb_up",
                                                            amount:
                                                              _vm.statistics
                                                                .status_totals
                                                                .opname_done,
                                                            description:
                                                              "Opname afgerond"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs6: "", md4: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "/reports?status=checking"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .status_totals[
                                                                  "checking"
                                                                ]
                                                              )
                                                            ],
                                                            icon:
                                                              "find_in_page",
                                                            amount:
                                                              _vm.statistics
                                                                .status_totals
                                                                .checking,
                                                            description:
                                                              "Te controleren"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs6: "", md4: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "/reports?status=veldwerk_rejected"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .status_totals[
                                                                  "veldwerk_rejected"
                                                                ]
                                                              )
                                                            ],
                                                            icon: "error",
                                                            amount:
                                                              _vm.statistics
                                                                .status_totals
                                                                .veldwerk_rejected,
                                                            description:
                                                              "Afgekeurd Schadebepaling"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs6: "", md4: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "/reports?status=zienswijze_veldwerk_rejected"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .status_totals[
                                                                  "zienswijze_veldwerk_rejected"
                                                                ]
                                                              )
                                                            ],
                                                            icon: "error",
                                                            amount:
                                                              _vm.statistics
                                                                .status_totals
                                                                .zienswijze_veldwerk_rejected,
                                                            description:
                                                              "Herzien rapport Afgekeurd Schadebepaling"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs6: "", md4: "" } },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "/reports?status=objection_veldwerk_rejected"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "StatisticsNumberWidget",
                                                      {
                                                        attrs: {
                                                          item: {
                                                            class: [
                                                              _vm.getKpiClass(
                                                                _vm.statistics
                                                                  .status_totals[
                                                                  "objection_veldwerk_rejected"
                                                                ]
                                                              )
                                                            ],
                                                            icon: "error",
                                                            amount:
                                                              _vm.statistics
                                                                .status_totals
                                                                .objection_veldwerk_rejected,
                                                            description:
                                                              "Bezwaar Afgekeurd Schadebepaling"
                                                          }
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { md12: "", lg6: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        wrap: "",
                                        "justify-space-between": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "info-icon",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openReadMoreDialog(
                                                                    "availability"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "info_outline"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3822138234
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("klik voor meer info")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-flex", [
                                        _c(
                                          "h2",
                                          { staticClass: "elementSubTitle" },
                                          [_vm._v("Beschikbaarheid")]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "text-center": ""
                                              }
                                            },
                                            [
                                              _c("StatisticsProgressWidget", {
                                                attrs: {
                                                  item: _vm.availability.week,
                                                  label:
                                                    _vm.progressLabels.week,
                                                  warningBoundry:
                                                    _vm.warningBoundryWeek,
                                                  okBoundry: _vm.okBoundryWeek
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "text-center": ""
                                              }
                                            },
                                            [
                                              _c("StatisticsProgressWidget", {
                                                attrs: {
                                                  item: _vm.availability.month,
                                                  label:
                                                    _vm.progressLabels.month,
                                                  warningBoundry:
                                                    _vm.warningBoundryWeek,
                                                  okBoundry: _vm.okBoundryWeek
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md12: "", lg6: "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        wrap: "",
                                        "justify-space-between": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "info-icon",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openReadMoreDialog(
                                                                    "planning"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "info_outline"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1820941782
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("klik voor meer info")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-flex", [
                                        _c(
                                          "h2",
                                          { staticClass: "elementSubTitle" },
                                          [_vm._v("Afspraken")]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "text-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to:
                                                      "/planning/planning-statistics?date=" +
                                                      _vm.date.toFormat(
                                                        "yyyy-MM-dd"
                                                      )
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "StatisticsProgressWidget",
                                                    {
                                                      attrs: {
                                                        item: _vm.planned.week,
                                                        label:
                                                          _vm.progressLabels
                                                            .week,
                                                        warningBoundry:
                                                          _vm.warningBoundryWeek,
                                                        okBoundry:
                                                          _vm.okBoundryWeek
                                                      }
                                                    }
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "text-center": ""
                                              }
                                            },
                                            [
                                              _c("StatisticsProgressWidget", {
                                                attrs: {
                                                  item: _vm.planned.month,
                                                  label:
                                                    _vm.progressLabels.month,
                                                  warningBoundry:
                                                    _vm.warningBoundryWeek,
                                                  okBoundry: _vm.okBoundryWeek
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "info-icon",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openReadMoreDialog(
                                                            "productie_week"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("info_outline")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1869144303
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("klik voor meer info")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-flex", [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v(
                                    "Productie " +
                                      _vm._s(_vm.progressLabels.week)
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum
                                                .STANDAARD,
                                              _vm.ProductionPeriodEnum.WEEK
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.standaard.week,
                                              label: "Standaard (30+)",
                                              warningBoundry:
                                                _vm.warningBoundryWeek,
                                              okBoundry: _vm.okBoundryWeek
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum
                                                .ZIENSWIJZE,
                                              _vm.ProductionPeriodEnum.WEEK
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.zienswijze.week,
                                              label: "Herzien Rapporten",
                                              warningBoundry:
                                                _vm.warningBoundryWeek,
                                              okBoundry: _vm.okBoundryWeek
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum.SPECIALS,
                                              _vm.ProductionPeriodEnum.WEEK
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.specials.week,
                                              label: "Specials",
                                              warningBoundry:
                                                _vm.warningBoundryWeek,
                                              okBoundry: _vm.okBoundryWeek
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum
                                                .OBJECTION,
                                              _vm.ProductionPeriodEnum.WEEK
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.objection.week,
                                              label: "Bezwaar",
                                              warningBoundry:
                                                _vm.warningBoundryWeek,
                                              okBoundry: _vm.okBoundryWeek
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum.WOCO_V1,
                                              _vm.ProductionPeriodEnum.WEEK
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item: _vm.production.woco_v1.week,
                                              label: "WoCo v1",
                                              warningBoundry:
                                                _vm.warningBoundryWeek,
                                              okBoundry: _vm.okBoundryWeek
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum.WOCO_V2,
                                              _vm.ProductionPeriodEnum.WEEK
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item: _vm.production.woco_v2.week,
                                              label: "WoCo v2",
                                              warningBoundry:
                                                _vm.warningBoundryWeek,
                                              okBoundry: _vm.okBoundryWeek
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum.VES,
                                              _vm.ProductionPeriodEnum.WEEK
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item: _vm.production.ves.week,
                                              label: "VES nulmeting",
                                              warningBoundry:
                                                _vm.warningBoundryWeek,
                                              okBoundry: _vm.okBoundryWeek
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "info-icon",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openReadMoreDialog(
                                                            "productie_maand"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("info_outline")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        714774708
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("klik voor meer info")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-flex", [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v(
                                    "Productie " +
                                      _vm._s(_vm.progressLabels.month)
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum
                                                .STANDAARD,
                                              _vm.ProductionPeriodEnum.MONTH
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.standaard.month,
                                              label: "Standaard (30+)",
                                              warningBoundry:
                                                _vm.warningBoundryMonth,
                                              okBoundry: _vm.okBoundryMonth
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum
                                                .ZIENSWIJZE,
                                              _vm.ProductionPeriodEnum.MONTH
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.zienswijze.month,
                                              label: "Herzien Rapporten",
                                              warningBoundry:
                                                _vm.warningBoundryMonth,
                                              okBoundry: _vm.okBoundryMonth
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum.SPECIALS,
                                              _vm.ProductionPeriodEnum.MONTH
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.specials.month,
                                              label: "Specials",
                                              warningBoundry:
                                                _vm.warningBoundryMonth,
                                              okBoundry: _vm.okBoundryMonth
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum
                                                .OBJECTION,
                                              _vm.ProductionPeriodEnum.MONTH
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.objection.month,
                                              label: "Bezwaar",
                                              warningBoundry:
                                                _vm.warningBoundryMonth,
                                              okBoundry: _vm.okBoundryMonth
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum.WOCO_V1,
                                              _vm.ProductionPeriodEnum.MONTH
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.woco_v1.month,
                                              label: "WoCo v1",
                                              warningBoundry:
                                                _vm.warningBoundryMonth,
                                              okBoundry: _vm.okBoundryMonth
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum.WOCO_V2,
                                              _vm.ProductionPeriodEnum.MONTH
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item:
                                                _vm.production.woco_v2.month,
                                              label: "WoCo v2",
                                              warningBoundry:
                                                _vm.warningBoundryMonth,
                                              okBoundry: _vm.okBoundryMonth
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", md4: "", lg3: "", xl2: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced"
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: _vm.getRepportageProductionLink(
                                              _vm.ProductionStatusEnum.VES,
                                              _vm.ProductionPeriodEnum.MONTH
                                            )
                                          }
                                        },
                                        [
                                          _c("StatisticsProgressWidget", {
                                            attrs: {
                                              item: _vm.production.ves.month,
                                              label: "VES nulmeting",
                                              warningBoundry:
                                                _vm.warningBoundryMonth,
                                              okBoundry: _vm.okBoundryMonth
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-space-between": "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "info-icon", attrs: { shrink: "" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openReadMoreDialog(
                                                  "rapport_aantallen"
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("info_outline")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("klik voor meer info")])]
                          )
                        ],
                        1
                      ),
                      _c("v-flex", [
                        _c("h2", { staticClass: "elementSubTitle" }, [
                          _vm._v("Rapport aantallen")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        _vm.organization
                          ? _c("RapportAantallenTabs", {
                              attrs: { "show-special-tab": true },
                              on: { tabChange: _vm.handleTabChanged },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var activeTab = ref.activeTab
                                      var tabs = ref.tabs
                                      return [
                                        _c("RapportAantallenPerStatus", {
                                          attrs: {
                                            "active-tab": activeTab,
                                            organization: _vm.organization,
                                            "show-special-tab": true,
                                            tabs: tabs,
                                            filters: _vm.filters
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1981873889
                              )
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-space-between": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", md4: "" } },
                        [
                          !_vm.isLoading
                            ? _c("PieChart", {
                                ref: "typesPieChart",
                                attrs: {
                                  data: _vm.contractKpi,
                                  type: "types",
                                  haslegendLink: true
                                },
                                on: {
                                  selectedCategory: _vm.handleSelectedCategory
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", md4: "" } },
                        [
                          !_vm.isLoading
                            ? _c("PieChart", {
                                ref: "typesPieChart",
                                attrs: {
                                  data: _vm.smallReportsLatePieChart,
                                  type: "types",
                                  haslegendLink: true
                                },
                                on: {
                                  selectedCategory: _vm.handleSelectedCategory
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", md4: "" } },
                        [
                          !_vm.isLoading
                            ? _c("PieChart", {
                                ref: "typesPieChart",
                                attrs: {
                                  data: _vm.largeReportsLatePieChart,
                                  type: "types",
                                  haslegendLink: true
                                },
                                on: {
                                  selectedCategory: _vm.handleSelectedCategory
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showOperationalKpis && false
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "info-icon",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openReadMoreDialog(
                                                            "key"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("info_outline")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        38429692
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("klik voor meer info")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-flex", [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v("Deskundigen uit termijn")
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "elementPanel elementPanel--spaced"
                            },
                            [
                              _c("DataTable", {
                                ref: "clientDatatable",
                                attrs: {
                                  options: _vm.tableOptions,
                                  visibility: _vm.visibility
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isWidgetDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isWidgetDialogOpen,
                callback: function($$v) {
                  _vm.isWidgetDialogOpen = $$v
                },
                expression: "isWidgetDialogOpen"
              }
            },
            [
              _c("MoreInfoWidgetDialog", {
                attrs: {
                  title: "Betekenis van waarden",
                  valueLeft: "Behaald door team",
                  valueRight: "Target van organisatie"
                },
                model: {
                  value: _vm.isWidgetDialogOpen,
                  callback: function($$v) {
                    _vm.isWidgetDialogOpen = $$v
                  },
                  expression: "isWidgetDialogOpen"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }