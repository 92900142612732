import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<MoreInfoWidgetDialog>({})
export default class MoreInfoWidgetDialog extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private valueLeft!: string;

  @Prop()
  private valueRight!: string;

  private close() {
    this.$emit('input', false);
  }
}
