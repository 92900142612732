var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "defaultDialog" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "dialog-close",
                            on: { click: _vm.close }
                          },
                          on
                        ),
                        [_vm._v("close")]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Sluiten")])]
          ),
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "align-end": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                    _c("h2", { staticClass: "dialog-title" }, [
                      _vm._v(" " + _vm._s(_vm.title) + " ")
                    ])
                  ]),
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("StatisticsProgressWidget", {
                          attrs: {
                            size: 280,
                            item: {
                              value: _vm.valueLeft,
                              target: _vm.valueRight,
                              percentage: 100
                            },
                            label: "",
                            warningBoundry: 0,
                            okBoundry: 0
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "mt-5": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { "justify-end": "", "align-center": "" } },
                          [
                            _c("v-flex", { attrs: { shrink: "" } }, [
                              _c(
                                "span",
                                {
                                  staticClass: "fadedText link mr-3",
                                  on: { click: _vm.close }
                                },
                                [_vm._v("Sluiten")]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }