import { EnumItemInterface } from '@/interfaces/EnumItemInterface';

export enum ProductionStatusEnum {
  STANDAARD = 'standaard',
  ZIENSWIJZE = 'zienswijze',
  SPECIALS = 'specials',
  OBJECTION = 'objection',
  WOCO_V1 = 'woco_v1',
  WOCO_V2 = 'woco_v2',
  VES = 'ves',
}

export type ProductionStatusItemInterface = EnumItemInterface<ProductionStatusEnum>;

export class ProductionStatusItemsClass implements Record<ProductionStatusEnum, ProductionStatusItemInterface> {
  public get [ProductionStatusEnum.STANDAARD](): ProductionStatusItemInterface {
    return {
      text: 'Standaard (30+)',
      value: ProductionStatusEnum.STANDAARD,
    };
  }

  public get [ProductionStatusEnum.ZIENSWIJZE](): ProductionStatusItemInterface {
    return {
      text: 'Herzien Rapporten',
      value: ProductionStatusEnum.ZIENSWIJZE,
    };
  }

  public get [ProductionStatusEnum.SPECIALS](): ProductionStatusItemInterface {
    return {
      text: 'Specials',
      value: ProductionStatusEnum.SPECIALS,
    };
  }

  public get [ProductionStatusEnum.OBJECTION](): ProductionStatusItemInterface {
    return {
      text: 'Bezwaar',
      value: ProductionStatusEnum.OBJECTION,
    };
  }

  public get [ProductionStatusEnum.WOCO_V1](): ProductionStatusItemInterface {
    return {
      text: 'WoCo v1',
      value: ProductionStatusEnum.WOCO_V1,
    };
  }

  public get [ProductionStatusEnum.WOCO_V2](): ProductionStatusItemInterface {
    return {
      text: 'WoCo v2',
      value: ProductionStatusEnum.WOCO_V2,
    };
  }

  public get [ProductionStatusEnum.VES](): ProductionStatusItemInterface {
    return {
      text: 'VES nulmeting',
      value: ProductionStatusEnum.VES,
    };
  }
}

export const ProductionStatusItems = new ProductionStatusItemsClass();
