import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { TableVisibility } from '@/components/data-table/DataTable';
import { AlertSystem } from '@/models/AlertSystem';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { getStatusLabel, ReportStatus } from '@/support/ReportStatus';
import { DateTime, Settings } from 'luxon';
// eslint-disable-next-line import/no-duplicates
import MoreInfoDialog from '@/components/dialog/more-info-dialog/MoreInfoDialog.vue';
// eslint-disable-next-line import/no-duplicates
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import MoreInfoWidgetDialog from '@/components/dialog/more-info-widget-dialog/MoreInfoWidgetDialog.vue';
import { Setting } from '@/models/Setting';
import { Rpc } from '@/models/Rpc';
import WeekStatisticsView, { StatisticsColumn, StatisticsOptions } from '@/components/week-statistics-view/WeekStatisticsView';
import { DashboardBureauInfo } from '@/support/Info';
import { firstDayOfWeek } from '@/support/String';
import PieChart from '@/components/pie-chart/PieChart.vue';
import { getKpiClass } from '@/support/Dashboard';
import { userRoles } from '@/models/User';
import { ProductionPeriodEnum } from '@/items/ProductionPeriod';
import { RawLocation } from 'vue-router';
import { ProductionStatusEnum } from '@/items/ProductionStatus';
import { cloneDeep } from 'lodash';
import { ReportAantallenFilter } from '@/views/ReportStatistics/RapportAantallenOhw/RapportAantallenOhw';
import { ReportTypes } from '@/support/ReportTypes';
import { organizationUuid } from '@/models/Organization';

@Component<DashboardBureau>({
  components: {
    PieChart,
    MoreInfoDialog,
    MoreInfoWidgetDialog,
    WeekStatisticsView,
    RapportAantallenPerStatus: () => import('@/views/ReportStatistics/RapportAantallen/RapportAantallenPerStatus/RapportAantallenPerStatus.vue'),
    RapportAantallenTabs: () => import('@/views/ReportStatistics/RapportAantallen/RapportAantallenTabs/RapportAantallenTabs.vue'),
  },
})
export default class DashboardBureau extends Vue {
  @Prop()
  protected departments!: string[];

  @Prop()
  protected organization!: string;

  @Prop({ default: false })
  protected showOperationalKpis!: boolean;

  @Prop({ default: () => DateTime.local() })
  protected date!: DateTime;

  public $pageTitle = 'Dashboard';

  protected ProductionStatusEnum = ProductionStatusEnum;

  protected ProductionPeriodEnum = ProductionPeriodEnum;

  protected isLoading = true;

  protected userRoles: object = userRoles;

  protected isReadingMoreInfo = false;

  protected isWidgetDialogOpen = false;

  protected description = '';

  // protected userDepartments: string[] = [];
  // protected selectedDepartments: string[] = [];

  protected statistics: any;

  protected contractKpi: any;

  protected smallReportsLatePieChart: any;

  protected largeReportsLatePieChart: any;

  protected settings: Setting[] = [];

  protected columns: StatisticsColumn[] = [];

  protected statisticsOptions: StatisticsOptions = {
    totalColumnName: 'reports_total',
  };

  // DataTable
  protected visibility: TableVisibility = {
    checkboxes: false,
    total: false,
    title: false,
    search: false,
    limit: false,
  };

  protected activeMoreInfo: MoreInfo = {
    title: 'Meer info',
    description: 'Deze uitleg ontbreekt nog...',
  };

  protected moreInfo: MoreInfo[] = DashboardBureauInfo;

  protected statusTotalsRegulier: any[] = [];

  protected statusTotalsZienswijzes: any[] = [];

  protected statusTotalsBezwaar: any[] = [];

  protected statusTotalsStuwmeer: any[] = [];

  protected currentTab = 'regulier';

  protected statusTotals: any[] = [
    {
      status: '',
      name: 'Regulier',
      data: {
        total: 0,
        external: 0,
        planned: 0,
        replanned: 0,
        todo: 0,
      },
      links: {
        total: '/reports?filter=total',
        external: '/reports?filter=external',
        planned: '/reports?filter=planned',
        replanned: '/reports?filter=replanned',
        todo: '/reports?filter=todo',
      },
    },
    {
      status: '',
      name: 'Nader Advies',
      data: {
        total: 0,
        external: 0,
        planned: 0,
        replanned: 0,
        todo: 0,
      },
      links: {
        total: '/reports?filter=total',
        external: '/reports?filter=external',
        planned: '/reports?filter=planned',
        replanned: '/reports?filter=replanned',
        todo: '/reports?filter=todo',
      },
    },
    {
      status: '',
      name: 'Bezwaar',
      data: {
        total: 0,
        external: 0,
        planned: 0,
        replanned: 0,
        todo: 0,
      },
      links: {
        total: '/reports?filter=total',
        external: '/reports?filter=external',
        planned: '/reports?filter=planned',
        replanned: '/reports?filter=replanned',
        todo: '/reports?filter=todo',
      },
    },
    {
      status: '',
      name: 'Stuwmeer',
      data: {
        total: 0,
        external: 0,
        planned: 0,
        replanned: 0,
        todo: 0,
      },
      links: {
        total: '/reports?filter=total',
        external: '/reports?filter=external',
        planned: '/reports?filter=planned',
        replanned: '/reports?filter=replanned',
        todo: '/reports?filter=todo',
      },
    },
  ];

  protected progressLabels = {
    week: '',
    month: '',
  };

  protected production = {};

  protected availability = {};

  protected planned = {};

  protected mounted() {
    Settings.defaultLocale = 'nl';
    this.initBreadcrumb();
    this.initialize();
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Dashboard' }],
    });
  }

  protected handleTabChanged(tab: string): void {
    this.currentTab = tab;
  }

  protected async initialize() {
    this.initReportNumbers();
    await this.getSettings();
    await this.fetchStatistics();

    this.createColumns();
    this.isLoading = false;
    this.$emit('isLoading', this.isLoading);
  }

  protected createColumns() {
    this.columns = [];

    this.columns.push(
      {
        key: 'name',
        name: 'Status',
        class: 'xs2 md2 lg2',
      },
      {
        key: 'external',
        name: 'Externe<br>Partij',
        class: 'xs2 text-right',
        format: () => 'Externe<br>Partij',
      },
      {
        key: 'planned',
        name: 'Toekomstig<br>gepland',
        class: 'xs2 text-right',
      },
      {
        key: 'replanned',
        name: 'Vervolg<br>Afspraak',
        class: 'xs2 text-right',
      },
      {
        key: 'todo',
        name: 'Onderhanden<br>werk',
        class: 'xs2 text-right',
      },
      {
        key: 'total',
        name: 'Totaal',
        class: 'xs2 text-right',
      },
    );
  }

  protected async fetchStatistics() {
    this.progressLabels.week = `Week ${this.date.toFormat('W')}`;
    this.progressLabels.month = `${this.date.toLocaleString({ month: 'long' })} ${this.date.year}`;

    const payload = {
      signature: 'management-reports:bureau-dashboard',
      body: {
        date: this.date.toFormat('yyyy-LL-dd'),
        departments: this.departments,
      },
    };

    if (! this.$store.state.isServiceOrganization && ! this.showOperationalKpis && this.isSupervisorView) {
      payload.body.departments = this.userDepartment;
    }

    await new Rpc()
      .dmz(this.organization)
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.statistics = response.data;
        this.availability = response.data.availability;
        this.planned = response.data.planned;
        this.production = response.data.production;

        const okPercentage = response.data.totals.ohw_total > 0 ? Math.round((100 * response.data.totals.ohw_total_ok) / response.data.totals.ohw_total) : 100;
        const overduePercentage = response.data.totals.ohw_total > 0 ? Math.round((100 * response.data.totals.ohw_overdue_kpi) / response.data.totals.ohw_total) : 0;

        this.contractKpi = [
          {
            name: `Binnen contract KPI - ${okPercentage}%`,
            color: '#67A7C4',
            y: response.data.totals.ohw_total_ok,
            id: 'ohw_total_ok',
          },
          {
            name: `Te laat - ${overduePercentage}%`,
            color: '#a09b1b',
            y: response.data.totals.ohw_overdue_kpi,
            id: 'ohw_overdue_kpi',
          },
        ];

        const okSmallPercentage = response.data.totals.ohw_small_total > 0 ? Math.round((100 * response.data.totals.ohw_small_total_ok) / response.data.totals.ohw_small_total) : 100;
        const overdueSmallPercentage = response.data.totals.ohw_small_total > 0 ? Math.round((100 * response.data.totals.ohw_small_overdue_kpi) / response.data.totals.ohw_small_total) : 0;

        this.smallReportsLatePieChart = [
          {
            name: `Standaard binnen contract KPI - ${okSmallPercentage}%`,
            color: '#67A7C4',
            y: response.data.totals.ohw_small_total_ok,
            id: 'ohw_total_ok',
          },
          {
            name: `Standaard te laat - ${overdueSmallPercentage}%`,
            color: '#a09b1b',
            y: response.data.totals.ohw_small_overdue_kpi,
            id: 'ohw_overdue_kpi',
          },
        ];

        const okLargePercentage = response.data.totals.ohw_large_total > 0 ? Math.round((100 * response.data.totals.ohw_large_total_ok) / response.data.totals.ohw_large_total) : 100;
        const overdueLargePercentage = response.data.totals.ohw_large_total > 0 ? Math.round((100 * response.data.totals.ohw_large_overdue_kpi) / response.data.totals.ohw_large_total) : 0;

        this.largeReportsLatePieChart = [
          {
            name: `Standaard (30+) binnen contract KPI - ${okLargePercentage}%`,
            color: '#67A7C4',
            y: response.data.totals.ohw_large_total_ok,
            id: 'ohw_total_ok',
          },
          {
            name: `Standaard (30+) te laat - ${overdueLargePercentage}%`,
            color: '#a09b1b',
            y: response.data.totals.ohw_large_overdue_kpi,
            id: 'ohw_overdue_kpi',
          },
        ];

        this.setStatusTotals();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected handleSelectedCategory(event: MouseEvent, serie: any) {
    if (serie.id === 'ohw_overdue_kpi') {
      this.$router.push('/reports?filter=kpi');
    }
  }

  protected initReportNumbers() {
    this.statusTotalsRegulier = [];
    this.statusTotalsZienswijzes = [];
    this.statusTotalsBezwaar = [];
    this.statusTotalsStuwmeer = [];

    const regulierStatus = [
      ReportStatus.PLANNING_ORGANIZATION,
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      ReportStatus.OPNAME_DONE,
      ReportStatus.IN_PROGRESS,
      ReportStatus.REJECTED,
      ReportStatus.SUBMITTED,
      ReportStatus.CHECKING,
      ReportStatus.VELDWERK_REJECTED,
    ];

    regulierStatus.map((status: string) => {
      this.statusTotalsRegulier.push({
        status,
        name: getStatusLabel(status),
        data: {
          total: 0,
          external: 0,
          planned: 0,
          replanned: 0,
          todo: 0,
        },
        links: {
          total: `/reports?status=${status}&filter=total&organizations=["${this.organization}"]`,
          external: `/reports?status=${status}&filter=external&organizations=["${this.organization}"]`,
          planned: `/reports?status=${status}&filter=planned&organizations=["${this.organization}"]`,
          replanned: `/reports?status=${status}&filter=replanned&organizations=["${this.organization}"]`,
          todo: `/reports?status=${status}&filter=todo&organizations=["${this.organization}"]`,
        },
      });
    });

    const zienswijzesStatus = [
      ReportStatus.ZIENSWIJZE_IN_PROGRESS,
      ReportStatus.ZIENSWIJZE_REJECTED,
      ReportStatus.ZIENSWIJZE_CHECKING,
      ReportStatus.ZIENSWIJZE_VELDWERK_REJECTED,
    ];

    zienswijzesStatus.map((status: string) => {
      this.statusTotalsZienswijzes.push({
        status,
        name: getStatusLabel(status),
        data: {
          total: 0,
          external: 0,
          planned: 0,
          replanned: 0,
          todo: 0,
        },
        links: {
          total: `/reports?status=${status}&filter=total&organizations=["${this.organization}"]`,
          external: `/reports?status=${status}&filter=external&organizations=["${this.organization}"]`,
          planned: `/reports?status=${status}&filter=planned&organizations=["${this.organization}"]`,
          replanned: `/reports?status=${status}&filter=replanned&organizations=["${this.organization}"]`,
          todo: `/reports?status=${status}&filter=todo&organizations=["${this.organization}"]`,
        },
      });
    });

    const bezwaarStatus = [
      ReportStatus.OBJECTION_PENDING_ASSIGNMENT,
      ReportStatus.OBJECTION_IN_PROGRESS,
      ReportStatus.OBJECTION_REJECTED,
      ReportStatus.OBJECTION_CHECKING,
      ReportStatus.OBJECTION_VELDWERK_REJECTED,
    ];

    bezwaarStatus.map((status: string) => {
      this.statusTotalsBezwaar.push({
        status,
        name: getStatusLabel(status),
        data: {
          total: 0,
          external: 0,
          planned: 0,
          replanned: 0,
          todo: 0,
        },
        links: {
          total: `/reports?status=${status}&filter=total&organizations=["${this.organization}"]`,
          external: `/reports?status=${status}&filter=external&organizations=["${this.organization}"]`,
          planned: `/reports?status=${status}&filter=planned&organizations=["${this.organization}"]`,
          replanned: `/reports?status=${status}&filter=replanned&organizations=["${this.organization}"]`,
          todo: `/reports?status=${status}&filter=todo&organizations=["${this.organization}"]`,
        },
      });
    });

    const stuwmeerStatus = [
      ReportStatus.SMR_IN_PROGRESS,
      ReportStatus.SMR_REJECTED,
      ReportStatus.SMR_CHECKING,
      ReportStatus.SMR_VELDWERK_REJECTED,
    ];

    stuwmeerStatus.map((status: string) => {
      this.statusTotalsStuwmeer.push({
        status,
        name: getStatusLabel(status),
        data: {
          total: 0,
          external: 0,
          planned: 0,
          replanned: 0,
          todo: 0,
        },
        links: {
          total: `/reports?status=${status}&filter=total&organizations=["${this.organization}"]`,
          external: `/reports?status=${status}&filter=external&organizations=["${this.organization}"]`,
          planned: `/reports?status=${status}&filter=planned&organizations=["${this.organization}"]`,
          replanned: `/reports?status=${status}&filter=replanned&organizations=["${this.organization}"]`,
          todo: `/reports?status=${status}&filter=todo&organizations=["${this.organization}"]`,
        },
      });
    });
  }

  protected getSettings() {
    new Setting()
      .dmz(this.organization)
      .all(false)
      .then((response: AxiosResponse) => {
        this.settings = response.data as Setting[];
      })
      .catch(() => {});
  }

  protected setStatusTotals() {
    this.statusTotalsRegulier.forEach((total) => {
      total.data.external = this.statistics.status_external[total.status] ? this.statistics.status_external[total.status] : 0;
      total.data.planned = this.statistics.status_planned[total.status] ? this.statistics.status_planned[total.status] : 0;
      total.data.replanned = this.statistics.status_replanned[total.status] ? this.statistics.status_replanned[total.status] : 0;
      total.data.todo = this.statistics.status_todo[total.status] ? this.statistics.status_todo[total.status] : 0;
      total.data.total = this.statistics.status_totals[total.status] ? this.statistics.status_totals[total.status] : 0;
    });

    this.statusTotalsBezwaar.forEach((total) => {
      total.data.external = this.statistics.status_external[total.status] ? this.statistics.status_external[total.status] : 0;
      total.data.planned = this.statistics.status_planned[total.status] ? this.statistics.status_planned[total.status] : 0;
      total.data.replanned = this.statistics.status_replanned[total.status] ? this.statistics.status_replanned[total.status] : 0;
      total.data.todo = this.statistics.status_todo[total.status] ? this.statistics.status_todo[total.status] : 0;
      total.data.total = this.statistics.status_totals[total.status] ? this.statistics.status_totals[total.status] : 0;
    });
    this.statusTotalsZienswijzes.forEach((total) => {
      total.data.external = this.statistics.status_external[total.status] ? this.statistics.status_external[total.status] : 0;
      total.data.planned = this.statistics.status_planned[total.status] ? this.statistics.status_planned[total.status] : 0;
      total.data.replanned = this.statistics.status_replanned[total.status] ? this.statistics.status_replanned[total.status] : 0;
      total.data.todo = this.statistics.status_todo[total.status] ? this.statistics.status_todo[total.status] : 0;
      total.data.total = this.statistics.status_totals[total.status] ? this.statistics.status_totals[total.status] : 0;
    });
    this.statusTotalsStuwmeer.forEach((total) => {
      total.data.external = this.statistics.status_external[total.status] ? this.statistics.status_external[total.status] : 0;
      total.data.planned = this.statistics.status_planned[total.status] ? this.statistics.status_planned[total.status] : 0;
      total.data.replanned = this.statistics.status_replanned[total.status] ? this.statistics.status_replanned[total.status] : 0;
      total.data.todo = this.statistics.status_todo[total.status] ? this.statistics.status_todo[total.status] : 0;
      total.data.total = this.statistics.status_totals[total.status] ? this.statistics.status_totals[total.status] : 0;
    });
    this.statusTotals.forEach((total) => {
      total.data.external = 0;
      total.data.planned = 0;
      total.data.replanned = 0;
      total.data.todo = 0;
      total.data.total = 0;
      if (total.name === 'Regulier') {
        this.statusTotalsRegulier.map((regulier) => {
          total.data.external += regulier.data.external;
          total.data.planned += regulier.data.planned;
          total.data.replanned += regulier.data.replanned;
          total.data.todo += regulier.data.todo;
          total.data.total += regulier.data.total;
        });
      }
      if (total.name === 'Nader Advies') {
        this.statusTotalsZienswijzes.map((regulier) => {
          total.data.external += regulier.data.external;
          total.data.planned += regulier.data.planned;
          total.data.replanned += regulier.data.replanned;
          total.data.todo += regulier.data.todo;
          total.data.total += regulier.data.total;
        });
      }
      if (total.name === 'Bezwaar') {
        this.statusTotalsBezwaar.map((regulier) => {
          total.data.external += regulier.data.external;
          total.data.planned += regulier.data.planned;
          total.data.replanned += regulier.data.replanned;
          total.data.todo += regulier.data.todo;
          total.data.total += regulier.data.total;
        });
      }
      if (total.name === 'Stuwmeer') {
        this.statusTotalsStuwmeer.map((regulier) => {
          total.data.external += regulier.data.external;
          total.data.planned += regulier.data.planned;
          total.data.replanned += regulier.data.replanned;
          total.data.todo += regulier.data.todo;
          total.data.total += regulier.data.total;
        });
      }
    });
  }

  protected openReadMoreDialog(key: string) {
    const activeMoreInfo = this.moreInfo.find((moreInfo: any) => moreInfo.key === key);
    if (activeMoreInfo) {
      this.activeMoreInfo = activeMoreInfo;
    }

    this.isReadingMoreInfo = true;
  }

  protected getKpiClass(amount: number, warningAt = 5, alertAt = 10, inverted = false) {
    return getKpiClass(amount, warningAt, alertAt, inverted);
  }

  protected getRepportageProductionLink(status: ProductionStatusEnum, period: ProductionPeriodEnum): RawLocation {
    return {
      path: '/rapportage/production',
      query: {
        filter: 'rapportage-production',
        headers: 'rapportage-production',
        organizations: this.organization !== organizationUuid.img ? [this.organization] : [],
        'production-from': cloneDeep(this.date).startOf(period).toFormat('yyyy-MM-dd'),
        'production-till': cloneDeep(this.date).endOf(period).toFormat('yyyy-MM-dd'),
        'production-status': status,
      },
    };
  }

  protected get tableOptions(): Object {
    return {
      model: new AlertSystem().setBadExpertsEndpoint(),
      sort: {
        key: 'total_expired_reports',
        order: 'DESC',
      },
      headers: [
        {
          text: this.$t('tableHeaders.name.title'),
          value: 'name',
        },
        {
          text: '# te laat',
          value: 'total_expired_reports',
          class: 'text-right',
          sortable: {
            key: 'total_expired_reports',
            order: 'ASC',
          },
        },
      ],
      actions: [],
    };
  }

  protected get okBoundryWeek() {
    if (this.isDateBeforeCurrentWeek) {
      return 100;
    }
    if (this.isDateAfterCurrentMonth) {
      return 0;
    }

    return Math.min(100, this.date.weekday * 0.2) * 100;
  }

  protected get warningBoundryWeek() {
    return this.okBoundryWeek * 0.9;
  }

  protected get okBoundryMonth() {
    if (this.isDateBeforeCurrentMonth) {
      return 100;
    }
    if (this.isDateAfterCurrentMonth) {
      return 0;
    }

    return Math.min(100, (this.date.day / this.date.daysInMonth) * 100);
  }

  protected get warningBoundryMonth() {
    return this.okBoundryMonth * 0.9;
  }

  protected get isDateBeforeCurrentWeek() {
    const monday = firstDayOfWeek(DateTime.local().startOf('day'));

    return this.date.diff(monday).milliseconds < 0;
  }

  protected get isDateBeforeCurrentMonth() {
    const thisMonth = DateTime.local().startOf('month');

    return this.date.diff(thisMonth).milliseconds < 0;
  }

  protected get isDateAfterCurrentWeek() {
    // Should check if this.date lies before the current week:
    return false;
  }

  protected get isDateAfterCurrentMonth() {
    // Should check if this.date lies before the current month:
    return false;
  }

  protected get userDepartment(): string[] {
    if (! this.$store.state.Auth.department) {
      return [];
    }

    return [this.$store.state.Auth.department.id];
  }

  protected get isSupervisorView(): boolean {
    return this.$store.state.Auth.supervisor_info && this.$store.state.Auth.supervisor_info.is_supervisor;
  }

  protected get filters() {
    const filters: ReportAantallenFilter = {
      exclude_types: this.currentTab === 'regulier' ? [ReportTypes.VES_NULMETING] : [],
      types: this.currentTab === 'ves' ? [ReportTypes.VES_NULMETING] : [],
    };

    return filters;
  }

  @Watch('organization')
  protected organizationChanged() {
    this.isLoading = true;
    this.$emit('isLoading', this.isLoading);
    this.initialize();
  }

  @Watch('date')
  @Watch('departments')
  protected dateChanged() {
    this.fetchStatistics();
  }

  @Watch('isReadingMoreInfo')
  protected isReadingMoreInfoChanged() {
    if (! this.isReadingMoreInfo) {
      this.activeMoreInfo = {
        title: 'Meer info',
        description: 'Deze uitleg ontbreekt nog...',
      };
    }
  }
}
