import { EnumItemInterface } from '@/interfaces/EnumItemInterface';

export enum ProductionPeriodEnum {
  WEEK = 'week',
  MONTH = 'month',
}

export type ProductionPeriodItemInterface = EnumItemInterface<ProductionPeriodEnum>;

export class ProductionPeriodItemsClass implements Record<ProductionPeriodEnum, ProductionPeriodItemInterface> {
  public get [ProductionPeriodEnum.WEEK](): ProductionPeriodItemInterface {
    return {
      text: 'Week',
      value: ProductionPeriodEnum.WEEK,
    };
  }

  public get [ProductionPeriodEnum.MONTH](): ProductionPeriodItemInterface {
    return {
      text: 'Maand',
      value: ProductionPeriodEnum.MONTH,
    };
  }
}

export const ProductionPeriodItems = new ProductionPeriodItemsClass();
